import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Donate = () => (
  <Layout>
    <SEO
      title="How to Help"
      description="Learn how to help the Holles adopt from Africa and be a part of their adoption story."
    />
    <div className="container">
      <div className="w-full max-w-2xl mx-auto py-12">
        <h1 className="text-4xl font-bold mb-8">How to Help</h1>
        <p>It is no secret that adoption is very expensive. We are blessed to work for a company that provides us with an annual salary while in China, but the cost of the adoption is more than our annual income.</p>
        <p>We don't believe we will have to go into debt; however, even if we take on some debt we believe it's worth it. We are convinced that God will financially provide for this adoption, and we believe that it will be through the generous and sacrificial giving of people like you.</p>
        <p>So to simply state it, we are asking you to help fund this adoption. Since we are asking for your help, it is our goal to be as transparent and vulnerable in our adoption story as possible. Thank you for considering entrusting a portion of your finances towards the adoption of a child.</p>

        <h2 className="text-2xl font-bold mb-6 pt-4">Make a Donation</h2>
        <p>Ultimately, we need help with the bottom line. The best way to help with this is to donate via the <a href="https://smallworldadoption.com/make-a-payment/" target="_blank" rel="noopener noreferrer">Small World</a> website. They are a 501(c)(3) which means the money you donate is tax-deductible. You will need to put <strong>"For The Child of Grant and Anna Holle"</strong> in the memo field to receive the tax benefits.</p>
        <p>
          <a className="btn small normal-case mr-4" href="https://smallworldadoption.com/make-a-payment/" target="_blank" rel="noopener noreferrer">Make a Donation</a>
          <a className="btn small normal-case" href="https://commerce.coinbase.com/checkout/69b4a64b-6fbf-4a4c-b18d-36986cfc1e1d" target="_blank" rel="noopener noreferrer">Donate Crypto Currency</a>
        </p>

        <h2 className="text-2xl font-bold mb-6 pt-4">Hire Grant!</h2>
        <p>Grant is happy to do some freelance work for some extra cash. If you know of someone needing some quality web work done, contact him with some project details to get started. His hourly rate is $50.</p>
        <p><a className="btn small normal-case" href="mailto:grant@holleadoption.com">Hire Grant</a></p>
      </div>
    </div>
  </Layout>
)

export default Donate
